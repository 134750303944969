.swiperContainer::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 100%;
  z-index: 40;
  background: linear-gradient(90deg, rgba(23, 24, 26, 0) 0%, white 100%);
  pointer-events: none;
}
